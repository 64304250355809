import { type CardProps as ChakraCardProps } from "@chakra-ui/react";
import { Link } from "../../components/ui/link";
import { ArticleCard } from "./ArticleCard";
import { EventCard } from "./EventCard";

export type CardSizes = "small" | "medium" | "large" | "tall";
export type CardType = "article" | "event";

export type CardProps = ChakraCardProps & {
	title?: string;
	text?: string;
	imageUrl?: string;
	url?: string;
	size?: CardSizes;
	date?: string | Date;
	indexOf: number;
	highlightedOnMobile?: boolean;
	numberOfLines?: number;
	cardType?: CardType;
	time?: string;
	location?: string;
};

const Card = ({
	title,
	text,
	imageUrl,
	url = "#",
	size = "small",
	highlightedOnMobile = true,
	date,
	numberOfLines,
	cardType = "article",
	time,
	location,
	...rest
}: CardProps) => {
	return (
		<Link data-group to={url} w={"100%"} h={"100%"}>
			{
				cardType === "article" ? (
					<ArticleCard
						title={title}
						text={text}
						imageUrl={imageUrl}
						url={url}
						size={size}
						highlightedOnMobile={highlightedOnMobile}
						date={date}
						numberOfLines={numberOfLines}
						cardType={cardType}
						{...rest}
					/>
				) : null
			}
			{
				cardType === "event" ? (
					<EventCard
						title={title}
						text={text}
						imageUrl={imageUrl}
						url={url}
						size={size}
						date={date}
						location={location}
						time={time}
						numberOfLines={numberOfLines}
						cardType={cardType}
						{...rest}
					/>
				) : null
			}
		</Link>
	)
};

export { Card };