import { Box, Flex } from "@chakra-ui/react";

export const LogoRow = (
	text: string,
	size: 'medium' | 'large' = 'medium',
	color = 'var(--chakra-colors-TechPrimary-green)'
) => {
	return (
		<Box
			fontFamily={'manifa'}
			fontWeight={'600'}
			lineHeight={{
				base: '1.625rem',
				lg: '2.75rem',
				xl: size === 'medium' ? '2.75rem' : '4.375rem',
			}}
			fontSize={{
				base: '2.3125rem',
				lg: '3.875rem',
				xl: size === 'medium' ? '3.875rem' : '6.25rem',
			}}
			letterSpacing={'0em'}
			color={color}
		>
			{text}
		</Box>
	)
};

const TechLogo = ({ size = 'medium', color = "var(--chakra-colors-TechPrimary-green)" }: {
	size?: 'medium' | 'large'
	color?: string
}) => {
	return (
		<Flex
			flexDirection={'column'}
		>
			{LogoRow('tech', size, color)}
			{LogoRow('nolo', size, color)}
			{LogoRow('gy', size, color)}
		</Flex>
	);
};

export { TechLogo };