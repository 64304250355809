import { type ComponentStyleConfig } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const text = (isRtl: boolean): ComponentStyleConfig => {
	const ts = isRtl ? 1.2 : 1;

	return {
		baseStyle: {
			lineHeight: 'base',
		},
		sizes: {
			display: {
				fontVariationSettings: "'wght' 70",
				fontSize: [
					`${ts * 5}rem`, // 
					null,
					null,
					`${ts * 6.2}rem`,
					`${ts * 6.875}rem`
				],
				lineHeight: "1",
				letterSpacing: [
					`${ts * -0.25}rem`, 
					null, 
					`${ts * -0.34375}rem`
				],
			},

			h1: {
				fontSize: [
					`${ts * 2.375}rem`, 
					null, 
					`${ts * 4}rem`
				],
				fontWeight: "400",
				lineHeight: `${ts * 1.1}`, 
			},
			h2: {
				fontSize: [
					`${ts * 1.75}rem`, 
					null, 
					`${ts * 3}rem`
				],
				fontWeight: "400",
				lineHeight: `${ts * 1.1}`,
			},
			h3: {
				fontSize: [
					`${ts * 1.25}rem`,
					null,
					`${ts * 2}rem`
				],
				fontWeight: "400",
				lineHeight: `${ts * 1.1}`,
			},
			h4: {
				fontSize: [
					`${ts * 1.125}rem`,
					null,
					`${ts * 1.5}rem`
				],
				fontWeight: "400",
				lineHeight: `${ts * 1.1}`,
			},
			h5: {
				fontSize: [
					`${ts * 0.875}rem`,
					null,
					`${ts * 1}rem`
				],
				fontWeight: "400",
				lineHeight: `${ts * 1.1}`,
			},
			bodyLarge: {
				fontSize: `${ts * 1.375}rem`,
				fontWeight: "500",
				lineHeight: `${ts * 1.5}`,
			},
			bodySmall: {
				fontSize: `${ts * 1.125}rem`,
				fontWeight: "500",
				lineHeight: `${ts * 1.5}`,
			},
			cta: {
				fontSize: `${ts * 0.853125}rem`,
				fontWeight: "400",
			},
			caption: {
				fontSize: [
					`${ts * 0.721875}rem`, 
					null, 
					`${ts * 0.875}rem`
				],
				textTransform: 'uppercase',
				fontWeight: "500",
				lineHeight: `${ts * 1.1}`,
				letterSpacing: ['-0.01375rem', null, '0']
			},
		},
		variants: {
			
		},
	}
}
