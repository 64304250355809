import { type FC } from "react";
import { Box, type BoxProps, Button, Heading, Text as ChakraText } from "@chakra-ui/react";
import { useTranslation } from "~/foundation/Dictionary";
import { CookieConsentStyling } from "./styles";
import { type GlobalDictionary } from "../../../dictionary";

type CookieConsentProps = BoxProps;

export const CookieConsent: FC<CookieConsentProps> = (props) => {
	const [t] = useTranslation<GlobalDictionary>();

	return (
		<Box
			className="onetrust-toggle optanon-category-C0004"
			data-src=""
			width="100%"
			height="100%"
			backgroundColor="rgba(0,0,0, .4)"
			color="TechBase.white"
			display="flex"
			flexWrap="wrap"
			alignContent="center"
			justifyContent="center"
			textAlign="center"
			{...props}
			{...CookieConsentStyling}
		>
			<Box className="onetrust-toggle__content">
				<Heading as="h3" size="desktopHeadline4" pb="3">
					{t("general.cookies.cookieTitle")}
				</Heading>
				<ChakraText className="onetrust-toggle__text" size="p16" mb="4">
					{t("general.cookies.cookieText")}
				</ChakraText>
				<Button padding="8px 17px 10px" variant="primaryOutline" onClick={() => {
					window.OneTrust?.ToggleInfoDisplay();
				}}>
					{t("general.cookies.cookieButtonText")}
				</Button>
			</Box>
		</Box>
	)
}