import { colors } from "../variables/colors";
import { type GetColorTheme } from "./dark-blue-color-theme";

export const getBlueColorTheme: GetColorTheme = () => ({
	name: "Blue",
	variants: {
		grey: {
			navigationFontColorPrimary: colors.TechBase.light,
			moduleBackgroundColor: colors.TechBase.dividerGrey,
			secondaryBackgroundColor: colors.TechBase.light,
			specialColor: colors.TechPrimary.darkBlue,
			headlineColor: colors.TechPrimary.darkBlue,
			bodyColor: colors.TechBase.dark,
			primaryColor: colors.TechPrimary.blue,
			cardBG: colors.TechBase.white,
			cardFontColor: colors.TechBase.dark,
			fontColorPrimary: colors.TechBase.dark,
			listCardBG: colors.TechBase.light,
			footerCtaColor: colors.TechBase.light,
			bannerLinkColor: colors.TechPrimary.darkBlue,

			highlightsColors: {
				primaryFont: colors.TechSpecial.darkBlue,
				secondaryFont: colors.TechPrimary.darkBlue,
				primaryBG: colors.TechPrimary.blue,
				secondaryBG: colors.TechBase.light,
				primarySubheaderFont: colors.TechSpecial.darkBlue,
				secondarySubheaderFont: colors.TechPrimary.darkBlue,
				primaryDescriptionFont: colors.TechBase.dark,
				secondaryDescriptionFont: colors.TechBase.dark
			}
		},
		white: {
			navigationFontColorPrimary: colors.TechBase.light,
			moduleBackgroundColor: colors.TechBase.light,
			secondaryBackgroundColor: colors.TechBase.grey,
			specialColor: colors.TechPrimary.darkBlue,
			headlineColor: colors.TechPrimary.darkBlue,
			bodyColor: colors.TechBase.dark,
			primaryColor: colors.TechPrimary.blue,
			cardBG: colors.TechBase.dividerGrey,
			cardFontColor: colors.TechBase.dark,
			fontColorPrimary: colors.TechBase.dark,
			listCardBG: colors.TechBase.light,
			footerCtaColor: colors.TechBase.light,
			bannerLinkColor: colors.TechPrimary.darkBlue,

			highlightsColors: {
				primaryFont: colors.TechPrimary.darkBlue,
				secondaryFont: colors.TechPrimary.darkBlue,
				primaryBG: colors.TechPrimary.blue,
				secondaryBG: colors.TechBase.dividerGrey,
				primarySubheaderFont: colors.TechPrimary.darkBlue,
				secondarySubheaderFont: colors.TechPrimary.darkBlue,
				primaryDescriptionFont: colors.TechBase.dark,
				secondaryDescriptionFont: colors.TechBase.dark
			}
		},
		themed: {
			navigationFontColorPrimary: colors.TechBase.light,
			moduleBackgroundColor: colors.TechPrimary.blue,
			secondaryBackgroundColor: colors.TechBase.light,
			specialColor: colors.TechSpecial.darkBlue,
			headlineColor: colors.TechSpecial.darkBlue,
			bodyColor: colors.TechBase.light,
			primaryColor: colors.TechPrimary.blue,
			cardBG: colors.TechSpecial.blue,
			cardFontColor: colors.TechBase.light,
			fontColorPrimary: colors.TechBase.light,
			listCardBG: colors.TechBase.light,
			footerCtaColor: colors.TechBase.light,
			heroTextColor: colors.TechBase.dark,
			bannerLinkColor: colors.TechBase.light,

			highlightsColors: {
				primaryFont: colors.TechBase.light,
				secondaryFont: colors.TechBase.light,
				primaryBG: colors.TechSpecial.blue,
				secondaryBG: colors.TechSpecial.blue,
				primarySubheaderFont: colors.TechBase.light,
				secondarySubheaderFont: colors.TechBase.light,
				primaryDescriptionFont: colors.TechBase.light,
				secondaryDescriptionFont: colors.TechBase.light
			}
		}
	}
});
