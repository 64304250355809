import { Box } from "@chakra-ui/react";
import { type ImageProps } from "./ImageTypes";
import BundleIcon from "../ui/bundle-icon";
import { useColorTheme } from "../../theme/ColorThemeContext";
import { useSitecoreContext } from "~/foundation/Jss";

const ImageComponent = ({ isImgSrc = false, isShadow = false, children }: ImageProps) => {
	const { sitecoreContext } = useSitecoreContext();
	const { primaryColor } = useColorTheme();

	return (
		<Box w="100%" h="100%" bg="TechBase.lightGrey" {...isShadow ? { boxShadow: { lg: "0px -10px 50px 0px #00000026" } } : {}} overflow="hidden" {...(!isImgSrc && sitecoreContext.pageEditing) ? { position: "relative", display: "flex", justifyContent: "center", alignItems: "center" } : {}}
			sx={{
				".LazyLoad": {
					width: "100%",
				}
			}}
		>
			{children}
			{!isImgSrc && sitecoreContext.pageEditing && <BundleIcon name="add-image" fill={primaryColor ?? "black"} size="lg" position="absolute" pointerEvents="none" />}
		</Box>
	)
}

export default ImageComponent;
