import { text as ThemeTextStyling } from "../../_Base/Theme/components/Text";

export const RichTextStyling = (isRtl: boolean) => ({
	sx: {
		...RichTextStylingBase(isRtl),
	},
});

export const RichTextStylingBase = (isRtl: boolean) => ({
	...ThemeTextStyling(isRtl).sizes?.bodyLarge,

	'p, li': {
		...ThemeTextStyling(isRtl).sizes?.bodyLarge,
		mb: '2rem',
		'&:last-child': {
			mb: '0',
		},
	},
	"h1, h2, h3, h4, h5, h6": {
		...ThemeTextStyling(isRtl).sizes?.h3,
		mb: ["2rem", "2rem", "3rem", "4rem"],
	},
	'a': {
		position: "relative",
		display: "inline",
		textDecoration: "underline",
		cursor: "pointer",
		"+ h1, + h2": {
			mt: ["8", null, null, "12"],
		},
	},
	'blockquote': {
		...ThemeTextStyling(isRtl).sizes?.h4,
		position: "relative",
		m: "2rem",
		my: "4rem",
		'&:before': {
			content: "'“'",
			fontSize: '5rem',
			color: "red",
			position: "absolute",
			top: "-3rem",
		},
	},
	'figure': {
		my: ["auto", "auto", "auto", "4rem"],
	},
	'.image-image': {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	'img': {
		m: 'auto',
		my: ["auto", "auto", "auto", "1rem"],
		scale: ["1", "1", "1", "1.1"],
		maxWidth: ["100%", "100%", "100%", "130%"],
	},
	'figcaption': {
		...ThemeTextStyling(isRtl).sizes?.bodySmall,
		color: "primary.darkGrey",
		mt: "0.5rem",
	}
});