import type { ReactNode } from "react";
import { Text, type TextProps } from "@chakra-ui/react";
import { useColorThemeVariant } from "../../theme/ColorThemeContext";

type BreadcrumbProps = TextProps & {
	children: ReactNode;
	isActive?: boolean;
}

export const Breadcrumb = ({
	children,
	isActive,
	...rest
}: BreadcrumbProps) => {
	const { headlineColor } = useColorThemeVariant("white");

	return (
		<Text
			variant={"breadcrumb"}
			color={isActive ? headlineColor : "TechBase.grey"}
			letterSpacing={".04em"}
			_hover={{
				textDecoration: "underline"
			}}
			{...rest}
		>
			{children}
		</Text>
	);
}