import { useEffect, useState } from "react";
import { Text, Flex, Box, Button } from "@chakra-ui/react";
import { type MainNavigationItemModel } from "~/feature/Navigation/generated-types";
import { useColorThemeVariant } from "../../../_Base/theme/ColorThemeContext";
import BundleIcon from "../../../_Base/components/ui/bundle-icon";
import { ButtonLink, NavLink } from "./NavLink";
import { groupHoverAfterArrowStyle } from "..";
import { useSitecoreContext } from "~/foundation/Jss";
import { pxToRem } from "../../../_Base/theme/utils/mapPxToRem";
import { Link } from "../../../_Base/components/ui/link";
import { rgba } from "polished";

const SubNavLink = ({
	link,
	onSubmenuToggle,
	isParentOpen,
	activeSubmenus,
	setActiveSubmenus,
	level,
	defaultOpen,
	first,
	parent,
	onClose,
}: {
	link: MainNavigationItemModel;
	onSubmenuToggle?: (link: MainNavigationItemModel) => void;
	isParentOpen?: boolean;
	activeSubmenus: { [key: string]: boolean };
	setActiveSubmenus: (submenus: { [key: string]: boolean }) => void;
	level: number;
	defaultOpen?: MainNavigationItemModel;
	parent?: MainNavigationItemModel;
	first?: boolean;
	onClose?: () => void;
}) => {
	const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
	const submenuId = `${level}-${(link.url)}`; // Unique identifier for this submenu
	const { moduleBackgroundColor, fontColorPrimary } = useColorThemeVariant("themed");
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;

	const primaryGradient = `${moduleBackgroundColor}.replace('rgb', 'rgba').replace(')', ', 0.93)')`;

	useEffect(() => {
		// Close the submenu if the parent is not open or this submenu is not active
		if (!isParentOpen || !activeSubmenus[submenuId]) {
			setIsSubmenuOpen(false);
		}
	}, [isParentOpen, activeSubmenus, submenuId]);

	const handleSubmenuToggle = () => {
		const updatedSubmenus = { ...activeSubmenus };
		if (isSubmenuOpen) {
			delete updatedSubmenus[submenuId];
		} else {
			// Close all submenus at this level and open the current one
			// also remove submenus that don't belong to the current level
			Object.keys(updatedSubmenus).forEach((key) => {
				if (key.startsWith(`${level}-`)) {
					delete updatedSubmenus[key];
				} else if (key.startsWith(`${level + 1}-`)) {
					delete updatedSubmenus[key];
				}
			});
			updatedSubmenus[submenuId] = true;
		}
		setActiveSubmenus(updatedSubmenus);
		setIsSubmenuOpen(!isSubmenuOpen);
		onSubmenuToggle?.(link);
	};

	const handleBackClick = () => {
		const updatedSubmenus = { ...activeSubmenus };
		delete updatedSubmenus[submenuId]; // Close current submenu
		if (parent) {
			updatedSubmenus[`${level - 1}-${parent?.url}`] = true; // Re-open parent submenu
		}
		setActiveSubmenus(updatedSubmenus);
		setIsSubmenuOpen(false); // Close current submenu visually
	};

	// Set the default open submenu
	useEffect(() => {
		if (defaultOpen && defaultOpen.url === link.url) {
			handleSubmenuToggle();
		}
	}, [defaultOpen, link.title]);

	return (
		<>
			{link.menuItemChildren && link.menuItemChildren.length > 0 ? (
				<ButtonLink
					link={link}
					onClick={() => handleSubmenuToggle()}
					isActive={isSubmenuOpen}
					hasSubmenu={link.menuItemChildren && link.menuItemChildren.length > 0}
					bolder={first}
				/>
			) : (
				<NavLink
					link={link}
					onClick={onClose}
					isActive={isSubmenuOpen || link.isAncestorOrSelf}
					hasSubmenu={link.menuItemChildren && link.menuItemChildren.length > 0}
					bolder={first}
				/>)}

			{link.menuItemChildren && link.menuItemChildren.length ? (
				<Box
					as="nav"
					sx={{
						position: "absolute",
						left: { base: "0", lg: !isRtl ? "100%" : "0" },
						right: { base: "0", lg: isRtl ? "100%" : "0" },
						top: 0,
						width: { base: "100vw", lg: '25vw' },
						zIndex: { base: "110", lg: "-10" },
						height: "100svh",
						backgroundColor: { base: !isSubmenuOpen ? primaryGradient : moduleBackgroundColor, lg: 'transparent' },
						visibility: isSubmenuOpen ? "visible" : "hidden",
						padding: {
							base: "3.125rem",
							lg: !isRtl ? "7.5rem 3.25rem 0 3.25rem" : "7.5rem 0px 0px 7.25rem",
							"2xl": !isRtl ? "7.5rem 7.25rem 0 3.25rem" : "7.5rem  0px 0px 7.25rem",
						},
						transition: {
							base: "all .25s ease-in",
							lg: isParentOpen ? "all .25s ease-in" : "all 0s",
						},
						transform: {
							base: isSubmenuOpen ? "translateX(0)" : "translateX(100%)",
							lg: "translateX(0)",
						},
					}}
					display={{ base: "flex", lg: "block" }}
					flexDirection="column"
					justifyContent={{ base: "end", lg: "flex-start" }}
					h={"100svh"}
				>
					<Box
						display={{ base: "block", lg: "none" }}
					>
						<Button
							sx={{
								position: "relative",
								marginBottom: "1.5rem",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
							onClick={() => handleBackClick()}
						>
							<Box
								sx={{
									transform: "rotate(180deg)",
									position: "relative",
									top: "2px",
								}}
							>
								<BundleIcon
									name="chevron"
									fill={fontColorPrimary}
									size="lg"
									width="16px"
									_groupHover={groupHoverAfterArrowStyle}
									isRtl={isRtl}
								/>
							</Box>
							{/* Back button here */}
							<Text fontWeight={"700"} color={fontColorPrimary} as="span" size="linkBtn" textTransform={"uppercase"}>
								Back
							</Text>
						</Button>

					</Box>


					<Flex
						as="ul"
						listStyleType="none"
						gap={6}
						flexDirection="column"
						className="fold-out-menu__submenu"
						opacity={{
							base: 1,
							lg: isSubmenuOpen ? 1 : 0,
						}}
						transition={isSubmenuOpen ? "opacity .35s" : "opacity 0s"}
						transitionDelay={
							{
								base: "0s",
								lg: isSubmenuOpen ? ".3s" : "0s",
							}
						}
					>
						<li>
							<Link
								to={link.url}
								onClick={onClose}
								_after={
									{
										content: '""',
										position: 'absolute',
										backgroundColor: fontColorPrimary ? `${rgba(fontColorPrimary, 0.5)}` : 'transparent',
										width: '100%',
										height: '1px',
										top: 'auto',
										bottom: '-.5em'
									}
								}
								sx={{
									textAlign: "left",
									position: "relative",
									width: "100%",
									opacity: {
										base: 1,
										lg: isSubmenuOpen ? 1 : 0,
									},
									transition: isSubmenuOpen && isParentOpen ? "opacity .35s" : "opacity 0s",
									transitionDelay: {
										base: "0s",
										lg: isSubmenuOpen ? ".2s" : "0s",
									},
								}}
							>
								<Text
									fontSize={pxToRem("22px")}
									lineHeight={pxToRem("36px")}
									color={fontColorPrimary}
									as="span"
								>
									{link.title || link.url}
								</Text>
							</Link>
						</li>
						{link.menuItemChildren.map((sublink, index) => (
							<li key={index}>
								<SubNavLink
									link={sublink}
									onSubmenuToggle={onSubmenuToggle}
									isParentOpen={isSubmenuOpen}
									activeSubmenus={activeSubmenus}
									setActiveSubmenus={setActiveSubmenus as (submenus: { [key: string]: boolean }) => void}
									level={level + 1}
									first={false}
									onClose={onClose}
								/>
							</li>
						))}
					</Flex>
					<Box
						as="hr"
						sx={{
							my: "2rem",
							borderBottom: `1px solid ${fontColorPrimary}`,
							opacity: "0.5",
							display: { base: "block", lg: "none" },
						}}
					/>
				</Box >
			) : null}
		</>
	);
};

export { SubNavLink };