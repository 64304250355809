import { Text, Box, Button } from "@chakra-ui/react";
import { type MainNavigationItemModel } from "~/feature/Navigation/generated-types";
import { useColorThemeVariant } from "../../../_Base/theme/ColorThemeContext";
import BundleIcon from "../../../_Base/components/ui/bundle-icon";
import { groupHoverAfterArrowStyle } from "..";
import { useSitecoreContext } from "~/foundation/Jss";
import { pxToRem } from "../../../_Base/theme/utils/mapPxToRem";
import { Link } from "../../../_Base/components/ui/link";

const hoverBorderStyles = (hoverColor: string) => ({
	content: '""',
	position: "absolute",
	display: "block",
	width: "100%",
	height: "0.125rem",
	bottom: "-.5rem",
	left: 0,
	backgroundColor: hoverColor,
	transform: "scaleX(0)",
	transition: "transform 0.2s ease",
	transformOrigin: "left",
});

const activeBorderStyles = (hoverColor: string) => ({
	content: '""',
	position: "absolute",
	display: "block",
	width: "100%",
	height: "0.125rem",
	bottom: "-.5rem",
	left: 0,
	backgroundColor: hoverColor,
	transformOrigin: "left",
});

const NavLink = ({
	link,
	onClick,
	isActive,
	hasSubmenu = false,
	bolder = false,
	showHoverBorder = false,
}:
	{
		link: MainNavigationItemModel;
		onClick?: (link: MainNavigationItemModel) => void,
		isActive?: boolean,
		hasSubmenu?: boolean,
		bolder?: boolean,
		showHoverBorder?: boolean,
	}) => {
	const { specialColor, navigationFontColorPrimary } = useColorThemeVariant("themed");
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;

	return (
		<Link
			to={
				link.url
			}
			onClick={() => {
				onClick && onClick(link);
			}}
			position="relative"
			pr={hasSubmenu ? "1rem" : "0"}
			display="flex"
			alignItems="center"
			_hover={{
				cursor: "pointer",
				_before: {
					transform: "scaleX(1)",
				},
			}}
			_before={
				(showHoverBorder && !isActive) ? hoverBorderStyles(navigationFontColorPrimary || specialColor) :
					(showHoverBorder && isActive) ? activeBorderStyles(navigationFontColorPrimary ?? specialColor) : {}
			}
		>
			<Text
				fontSize={pxToRem("22px")}
				lineHeight={pxToRem("36px")}
				color={isActive ? (showHoverBorder ? navigationFontColorPrimary : specialColor) : navigationFontColorPrimary}
				as="span"
				fontWeight={bolder || isActive ? "bold" : "400"}
				sx={{
					_hover: {
						color: showHoverBorder ? navigationFontColorPrimary : specialColor,
					}
				}}
			>
				{link.title || link.url}
			</Text>
			{hasSubmenu ?
				<Box
					sx={{
						right: !isRtl ? "0" : "auto",
						left: !isRtl ? "auto" : "0",
						position: "absolute",
						top: "0",
					}}
				>
					<BundleIcon
						name="chevron"
						fill={isActive ? specialColor : navigationFontColorPrimary}
						size="lg"
						width="17px"
						_groupHover={groupHoverAfterArrowStyle}
						isRtl={isRtl}
					/>
				</Box> : null}
		</Link>
	);
};

const ButtonLink = ({
	link, onClick,
	isActive,
	hasSubmenu = false,
	bolder = false,
	showHoverBorder = false,
}:
	{
		link: MainNavigationItemModel;
		onClick?: (link: MainNavigationItemModel) => void,
		isActive?: boolean,
		hasSubmenu?: boolean,
		bolder?: boolean,
		showHoverBorder?: boolean,
	}) => {
	const { specialColor, navigationFontColorPrimary } = useColorThemeVariant("themed");
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	return (
		<Button
			onClick={() => {
				if (link.menuItemChildren && link.menuItemChildren.length) {
					onClick && onClick(link);
				}
			}}
			position="relative"
			pr={hasSubmenu ? "1rem" : "0"}
			display="flex"
			justifyContent={"flex-start"}
			textAlign={"left"}
			w={"100%"}
			_hover={{
				cursor: "pointer",
				_before: {
					transform: "scaleX(1)",
				},
			}}
			_before={
				(showHoverBorder && !isActive) ? hoverBorderStyles(navigationFontColorPrimary || specialColor) :
					(showHoverBorder && isActive) ? activeBorderStyles(navigationFontColorPrimary || specialColor) : {}
			}
		>
			<Text
				fontSize={pxToRem("22px")}
				lineHeight={pxToRem("36px")}
				color={isActive ? (showHoverBorder ? navigationFontColorPrimary : specialColor) : navigationFontColorPrimary}
				as="span"
				fontWeight={bolder || isActive ? "bold" : "400"}
				sx={{
					_hover: {
						color: showHoverBorder ? navigationFontColorPrimary : specialColor,
					}
				}}
			>
				{link.title || link.url}
			</Text>
			{hasSubmenu && <Box
				sx={{
					right: !isRtl ? "0" : "auto",
					left: !isRtl ? "auto" : "0",
					position: "absolute",
					top: "0",
				}}
			>
				<BundleIcon
					name="chevron"
					fill={isActive ? specialColor : navigationFontColorPrimary}
					size="lg"
					width="17px"
					_groupHover={groupHoverAfterArrowStyle}
					isRtl={isRtl}
				/>
			</Box>}
		</Button>
	)

}

export { NavLink, ButtonLink };