import { Container as ChakraContainer, type ContainerProps } from "@chakra-ui/react";
import { pxToRem } from "../theme/utils/mapPxToRem";

const Container = ({ children, ...rest }: ContainerProps) => {
	return (
		<ChakraContainer
			variant="responsive"
			// border={{
			// 	base: '10px solid green',
			// 	sm: '10px solid pink',
			// 	md: '10px solid yellow',
			// 	lg: '10px solid red',
			// 	xl: "10px solid blue",
			// 	"2xl": "10px solid orange",
			// 	"3xl": "10px solid purple",
			// }}
			paddingInlineStart={{
				base: '1rem',
				sm: '1rem',
				md: '1rem',
				lg: pxToRem("104px"),
				"2xl": '0',
			}}
			paddingInlineEnd={
				{
					base: '1rem',
					sm: '1rem',
					md: '1rem',
					lg: pxToRem("104px"),
					"2xl": '0',
				}
			}
			maxW={
				{
					base: '100%',
					sm: '100%',
					md: '100%',
					lg: '100%',
					xl: '1184px',
				}
			}
			margin={'0 auto'}
			{...rest}
		>
			{children}
		</ChakraContainer>
	);
};

export { Container };