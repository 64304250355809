export const videoThumbnailMotion = {
	blur: {
		scale: 1,
		transition: {
			duration: .5,
			ease: "easeOut"
		}
	},
	hover: {
		scale: 1.015,
		transition: {
			duration: .5,
			ease: "easeOut"
		}
	}
};