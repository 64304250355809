import { useEffect, useState, type FC } from "react";
import { type MainNavigationProps } from "../../generated-types";
import { Flex, Box } from "@chakra-ui/react";
import { type MainNavigationItemModel } from "~/feature/Navigation/generated-types";
import { useNavigationContext } from "./NavigationProvider";
import { SubNavigation } from "./Components/SubNavigation";
import { ButtonLink, NavLink } from "./Components/NavLink";
import { LanguageSelector } from "./Components/LanguageSelector";
import { useSitecoreContext } from "~/foundation/Jss";
import { useColorThemeVariant } from "../../_Base/theme/ColorThemeContext";

export const groupHoverAfterArrowStyle = {
	width: ["auto", null, null, "0px"],
};

// Main Navigation component
const MainNavigation: FC<MainNavigationProps> = (props) => {
	const { rendering } = props;
	const [links, setLinks] = useState<MainNavigationItemModel[] | undefined>(undefined);
	const { isMobileMenuOpen, setIsMobileMenuOpen } = useNavigationContext();
	const [defaultOpen, setDefaultOpen] = useState<MainNavigationItemModel | undefined>(undefined);
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const { navigationFontColorPrimary } = useColorThemeVariant("themed");

	const handleMenuClick = (link: MainNavigationItemModel) => {
		setDefaultOpen(link);
		setIsMobileMenuOpen && setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	useEffect(() => {
		const items = rendering?.customData?.menuItems;

		if (!items) {
			return;
		}

		setLinks(items);
	}, [rendering]);

	return (
		<section data-rendering="MainNavigation">
			<Box>
				<Flex
					as="ul"
					listStyleType="none"
					gap={6}
					pt={3}
					display={{ base: "none", lg: "flex" }}
					opacity={isMobileMenuOpen ? 0 : 1}
					transition="opacity .3s ease-in"
				>
					{links && links?.map((link, index) => (
						<li key={index}>
							{link.menuItemChildren && link.menuItemChildren.length > 0 ? (
								<ButtonLink
									link={link}
									onClick={() => handleMenuClick(link)}
									isActive={link.isAncestorOrSelf}
									showHoverBorder={true}
									bolder={true}
								/>
							) : (
								<NavLink
									link={link}
									isActive={link.isAncestorOrSelf}
									showHoverBorder={true}
									bolder={true}
								/>
							)}

						</li>
					))}
					<Box
						as="li"
						pos={"relative"}
						paddingLeft={!isRtl ? ".8rem" : "0"}
						marginLeft={!isRtl ? ".5rem" : "0"}
						marginRight={!isRtl ? "0" : ".5rem"}
						paddingRight={!isRtl ? "0" : ".8rem"}
						sx={{
							_before: {
								position: "absolute",
								left: !isRtl ? "-10px" : "auto",
								right: !isRtl ? "auto" : "-10px",
								content: "''",
								display: "block",
								height: "1.5rem",
								width: "2px",
								bgColor: navigationFontColorPrimary,
								top: "7px",
							}
						}}
					>
						<LanguageSelector />
					</Box>
				</Flex>
			</Box>
			<SubNavigation
				isOpen={isMobileMenuOpen}
				close={() => {
					setDefaultOpen(undefined);
					setIsMobileMenuOpen && setIsMobileMenuOpen(false);
				}}
				links={links}
				defaultOpen={defaultOpen}
			/>
		</section>
	);
};

export default MainNavigation;
