import type { ReactNode } from "react";
import { Heading, type HeadingProps } from "@chakra-ui/react";
import { useColorTheme } from "../../theme/ColorThemeContext";

type HeadlineProps = HeadingProps & {
	children: ReactNode;
}

export const Headline = ({ children, ...rest }: HeadlineProps) => {
	const { headlineColor } = useColorTheme();

	return (
		<Heading
			as="h3"
			color={headlineColor}
			// sx={{
			// 	textWrap: "balance",
			// }}
			{...rest}
		>
			{children}
		</Heading>
	)
}

export const SubHeading = ({ children, ...rest }: HeadlineProps) => {
	const { headlineColor } = useColorTheme();
	return (
		<Headline as="h4" size="subheader" color={headlineColor} {...rest}>{children}</Headline>
	)
};
