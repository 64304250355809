import { useSitecoreContext, type LinkField, type TextField } from "~/foundation/Jss";
import { Box, Flex as ChakraFlex } from "@chakra-ui/react";
import { sc } from "../Jss";
import { pxToRem } from "../theme/utils/mapPxToRem";
import { Headline, SubHeading } from "./ui/headline";
import { Flex } from "../theme/flex";
import { useColorTheme, useColorThemeVariant } from "../theme/ColorThemeContext";
import { ModuleLink } from "./ui/ModuleLink";

export type ModuleHeadlineProps = {
	headline?: TextField;
	link?: LinkField;
	subHeadline?: TextField;
	isRtl?: boolean;
	useWhiteThemeVariant?: boolean;
}

const ModuleHeadline = ({
	headline,
	link,
	subHeadline,
	useWhiteThemeVariant = false
}: ModuleHeadlineProps) => {
	const { sitecoreContext } = useSitecoreContext();
	const { headlineColor, bodyColor } = !useWhiteThemeVariant ? useColorTheme() : useColorThemeVariant("white");

	return (
		(headline?.value || subHeadline?.value || link?.value?.href || sitecoreContext.pageEditing) &&
		<Box mb={["2rem", null, "4rem"]} className="module-headline">
			<Flex
				flexDir={["column", null, null, "row"]}
				alignItems={["flex-start", null, null, "flex-end"]}
				gap="0px"
			>
				<ChakraFlex
					flexDir="column"
					flex="0 0 auto"
					w={["80%", null, null, "50%"]}
				>
					{(headline?.value || sitecoreContext.pageEditing) &&
						<Headline
							as="h2"
							color={headlineColor}
							size="headline2"
						>
							<sc.Text field={headline} />
						</Headline>}
					{(subHeadline?.value || sitecoreContext.pageEditing) &&
						<SubHeading
							as="p"
							color={bodyColor}
							mt={[pxToRem("16px"), null, null, pxToRem("16px")]}
						>
							<sc.Text field={subHeadline} />
						</SubHeading>
					}
				</ChakraFlex>
				<ChakraFlex
					justifyContent={["flex-start", null, null, "flex-end"]}
					flex="1"
					mt={[pxToRem("22px"), null, null, "0"]}
				>
					{(link?.value.href || sitecoreContext.pageEditing) &&
						<ModuleLink
							link={link}
							hasIcon={true}
						/>
					}
				</ChakraFlex>
			</Flex>
		</Box>
	);
}

export { ModuleHeadline };