
import { getDarkBlueColorTheme } from "./color-themes/dark-blue-color-theme";
import { getBlueColorTheme } from "./color-themes/blue-color-theme";
import { getDarkGreenColorTheme } from "./color-themes/dark-green-color-theme";
import { getGreyColorTheme } from "./color-themes/grey-color-theme";
import { getGreenExtraColorTheme } from "./color-themes/green-extra-color-theme";

export const themes = {
	"dark_blue": getDarkBlueColorTheme,
	"blue": getBlueColorTheme,
	"dark_green": getDarkGreenColorTheme,
	"grey": getGreyColorTheme,
	"green_extra": getGreenExtraColorTheme,
};

export type ColorThemeId = keyof typeof themes;