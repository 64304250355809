import { useSitecoreContext } from "~/foundation/Jss";
import { ColorThemeProvider } from "./ColorThemeContext";
import { getColorThemeVariant } from "./getColorTheme";
import { type AramcoTechProjectDataContext, type ThemePickerBase, type ThemeRenderingParameters } from "../../generated-types";
import { type ColorThemeId } from "./themes";
import { type ThemeVariant, type VariantThemeId } from "./color-themes/dark-blue-color-theme";

export type WithThemeProps = {
	params: ThemeRenderingParameters;
}

export function themable(allowedThemes: ThemeVariant[] | null = null) {
	return function themable<P extends WithThemeProps>(InputComponent: React.FC<P>) {

		const Themable = (props: P) => {
			const { sitecoreContext } = useSitecoreContext<ThemePickerBase, AramcoTechProjectDataContext>();
			let pageTheme = (sitecoreContext?.custom.projectData.pageTheme || "dark_blue") as ColorThemeId;
			let variant = (props.params.themePicker || "white") as VariantThemeId;

			if (variant?.includes("|")) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				[pageTheme, variant] = variant.split('|') as [ColorThemeId, VariantThemeId];
			}

			const colorTheme = getColorThemeVariant(pageTheme, variant);
			if (colorTheme) {
				return (
					<ColorThemeProvider colorTheme={colorTheme}>
						<InputComponent {...props} />
					</ColorThemeProvider>
				);
			}

			return <InputComponent {...props} />
		}

		Themable.allowedThemes = allowedThemes;
		Themable.displayName = "Themable";

		return Themable;
	};
}