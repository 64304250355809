import { useSitecoreContext } from "~/foundation/Jss";
import { NavLink } from "./NavLink";

const LanguageSelector = ({
	onClick,
}: {
	onClick?: () => void,
}) => {
	const { sitecoreContext } = useSitecoreContext();
	const isArabic = sitecoreContext.language === "ar";
	const itemPath = sitecoreContext.itemPath;

	/**
	 * Manipulate url so that if language is english, the
	 * arabic version should be appended to the url. 
	 * If there is /en in the url, it should be replaced with /ar.
	 * If there is /ar in the url, it should be replaced with /en.
	 * If there is no /en or /ar in the url, /ar should be appended to the url
	 * based on the language.
	 * 
	 * @param link 
	 */
	const manipulateUrl = (url: string) => {


		if (!url || url === "/") {
			if (isArabic) {
				return "/en";
			}
			return "/ar";
		}

		if (!isArabic) {
			if (url.includes("/en/")) {
				return url.replace("/en/", "/ar/");
			}

			// if no lanugage prefix add to url
			return `/ar${url}`;
		}

		if (isArabic) {
			if (url.includes("/ar/")) {
				return url.replace("/ar/", "/en/");
			}

			// if no lanugage prefix add to url
			return `/en${url}`;
		}

		return isArabic ? url : `${url}/ar`;
	};
	return (
		<NavLink
			link={{
				url: manipulateUrl(String(itemPath) ?? ""),
				title: isArabic ? "English" : "العربية",
				includeMenuLink: false,
				automaticallyExpandChildren: false,
				isCurrentPage: false,
				isAncestorOrSelf: false,
			}}
			onClick={onClick}
			showHoverBorder={true}
			bolder={true}

		/>
	)
};

export { LanguageSelector };