/* eslint-disable @typescript-eslint/no-explicit-any, no-restricted-imports */
import {
	type RouteData as DefaultRouteData,
	useSitecoreContext as defaultUseSitecoreContext,
	type LayoutServiceContext as DefaultLayoutServiceContext,
	type LayoutServiceContextData as DefaultLayoutServiceContextData,
	type WithSitecoreContextOptions
} from "@sitecore-jss/sitecore-jss-react";
import { type CustomContext } from "./generated-types";

export type LayoutServiceContextData = Omit<DefaultLayoutServiceContextData, "context"> & {
	context: SitecoreContextValue;
}

export type LayoutServiceData<TContextFields = any> = {
	sitecore: LayoutServiceContextData & {
		route: RouteData<TContextFields> | null;
	};
}

export type RouteData<TContextFields = any> = Omit<DefaultRouteData, "fields"> & {
	fields?: TContextFields;
}

export type SitecoreContextValue<TContextFields = any, TProjectDataContext = unknown> = DefaultLayoutServiceContext & {
	route?: RouteData<TContextFields>;
	itemId: string;
	custom: Omit<CustomContext, "projectData"> & { projectData: TProjectDataContext };
}

export type UseSitecoreContext<TContextFields = any, TProjectDataContext = unknown> = Omit<ReturnType<typeof defaultUseSitecoreContext>, "sitecoreContext"> & {
	sitecoreContext: SitecoreContextValue<TContextFields, TProjectDataContext>;
};

export function useSitecoreContext<TContextFields, TProjectDataContext = unknown>(options?: WithSitecoreContextOptions) {
	return defaultUseSitecoreContext(options) as UseSitecoreContext<TContextFields, TProjectDataContext>;
}