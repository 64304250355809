import { type VideoComponentProps } from "./VideoTypes";
import { useSitecoreContext } from "~/foundation/Jss";
import { sc } from "../../../_Base/Jss";
import { Text, Flex as ChakraFlex } from "@chakra-ui/react";
import { Flex } from "../../theme/flex";
import { pxToRem } from "../../../_Base/theme/utils/mapPxToRem";
import VideoComponent from "./VideoComponent";
import { type GlobalDictionary } from "../../../dictionary";
import { useTranslation } from "~/foundation/Dictionary";

const VideoWithCaption = ({ videoId, caption, isAspectRatio = true, isOverlay = false, isFullWidth = false, children, ...rest }: VideoComponentProps) => {
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const [t] = useTranslation<GlobalDictionary>();

	return (
		<>
			{
				isAspectRatio ?
					<VideoComponent videoId={videoId} isAspectRatio={isAspectRatio} isOverlay={isOverlay} {...rest}>
						{children}
					</VideoComponent>
					:
					<ChakraFlex justifyContent="center" alignItems="center" width="100%" height={["250px", null, null, "460px"]} sx={{
						".LazyLoad": {
							width: "100%",
						}
					}}>
						<VideoComponent videoId={videoId} isAspectRatio={isAspectRatio} isOverlay={isOverlay} {...rest}>
							{children}
						</VideoComponent>
					</ChakraFlex>
			}
			{(caption?.value || sitecoreContext.pageEditing) &&
				<Text as="caption" size="imageCaption" mt={pxToRem("12px")} display={["none", null, null, isFullWidth ? null : "flex"]} textAlign={isRtl ? 'right' : 'left'}>
					<sc.Text field={caption} />
				</Text>
			}
			{sitecoreContext.pageEditing &&
				<Flex mt={pxToRem("10px")}>
					<Text as="p" size="body" mb={pxToRem("10px")} color="black" flex="0 0 auto">
						{t("general.labels.videoIdLabel")}
					</Text>
					<Text as="p" size="body" border="1px solid black" mb={pxToRem("10px")} color="black" flex="1">
						<sc.Text field={videoId} />
					</Text>
				</Flex>
			}
		</>
	)
}

export default VideoWithCaption;