import { Box, Flex, Text } from "@chakra-ui/react";
import { LazyImage } from "~/foundation/Components/Image/LazyImage";
import { Headline } from "../../components/ui/headline";
import BundleIcon from "../../components/ui/bundle-icon";
import { pxToRem } from "../../theme/utils/mapPxToRem";
import { colors } from "../../theme/variables/colors";
import { useSitecoreContext } from "~/foundation/Jss";
import { useColorThemeVariant } from "../../theme/ColorThemeContext";
import { type CardProps } from "./Card";

const groupHoverBeforeArrowStyle = {
	opacity: 1,
	transform: "translateX(8px)",
};

const ArticleCard = ({
	title,
	text,
	imageUrl,
	size = "small",
	highlightedOnMobile = true,
	date,
	numberOfLines,
	...rest
}: CardProps) => {
	let aspectRatio = size === "small" ? "1 / 1" : "16 / 9";
	if (size === "tall") {
		aspectRatio = "281 / 332";
	}
	const aspectRatioMobile = "253 / 198";
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const { listCardBG, fontColorPrimary } = useColorThemeVariant("white");
	const { cardFontColor } = useColorThemeVariant("white");

	function formatDate(input: Date | string): string {
		const date = typeof input === "string" ? new Date(input) : input;

		if (isNaN(date.getTime())) {
			throw new Error("Invalid date input");
		}

		const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
		return date.toLocaleDateString('en-US', options);
	}

	return (
		<Flex
			flexDirection={"column"}
			w={"100%"}
			cursor={"pointer"}
			borderTop={!imageUrl ? `2px solid ${colors.TechBase.greyBorder}` : "none"}
			bgColor={listCardBG}
			pt={!imageUrl ? pxToRem("24px") : "0"}
			{...rest}
		>
			{imageUrl ? <Box
				pos={"relative"}
				aspectRatio={{
					base: highlightedOnMobile ? aspectRatioMobile : "1 / 1",
					md: aspectRatio
				}}
				overflow={"hidden"}
				mb={pxToRem("24px")}
			>
				{/* <Box
					pos={"absolute"}
					top={"0"}
					left={"0"}
					width={"100%"}
					height={"100%"}
					zIndex={1}
				/> */}
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						zIndex: 1,
						background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 50.18%, rgba(0, 0, 0, 0.2) 82.05%)",
					}}
				/>
				{imageUrl && <LazyImage
					src={imageUrl}
					alt={title}
					width={"100%"}
					height={"calc(100% + 8px)"}
					objectFit={"cover"}
					objectPosition={"top"}
					transition={"all 0.3s ease"}
					opacity={0}
					transform={"translateY(-8px)"}
					zIndex={2}
					onLoad={(e) => {
						(e.target as HTMLImageElement).style.opacity = "1";
					}}
					_groupHover={{
						transform: "translateY(0)",
					}}
				/>}
				<BundleIcon
					name="button-arrow"
					fill={"#fff"}
					size="lg"
					flex="0 0 auto"
					width={
						{
							base: pxToRem("16px"),
							md: pxToRem("28px"),
						}
					}
					// m={isRtl ? "0 0 0 16px" : "0 16px 0 0"}
					transition="all 0.3s ease"
					_groupHover={groupHoverBeforeArrowStyle}
					isRtl={isRtl}
					pos={"absolute"}
					zIndex={3}
					bottom={{
						base: "1rem",
						lg: pxToRem("20px"),
					}}
					left={{
						base: "1rem",
						lg: pxToRem("24px"),
					}}
				/>
			</Box> : null}

			{date &&
				<Text
					size={"smallBodyBold"}
					textTransform={"uppercase"}
					mb={pxToRem("8px")}
					color={fontColorPrimary}
				>
					{formatDate(date)}
				</Text>}

			<Headline
				size="headline5"
				mb={pxToRem("8px")}
				color={cardFontColor}
			>
				{title}
			</Headline>
			<Text
				size="body"
				color={cardFontColor}
				display={"-webkit-box"}
				sx={{
					WebkitLineClamp: numberOfLines || "none",
					WebkitBoxOrient: "vertical",
				}}
				overflow={"hidden"}
			>
				{text}
			</Text>
		</Flex>
	)
};

export { ArticleCard };