export const breakpoints = {
	// 480px
	sm: '30rem',
	//  768px
	md: '48rem',
	//  992px    
	lg: '62rem',
	// 1280px
	xl: '80rem',
	// 1348px
	"2xl": '84.25rem',
	// 1536px
	'3xl': '96rem',
	// 1920px and up
	'4xl': '120rem',
	// 2240 and up
	'5xl': '140rem',
	// 2560 and up
	'6xl': '160rem'
};