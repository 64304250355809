import { type VariantThemeId, type ThemeVariantColors, type ColorTheme } from "./color-themes/dark-blue-color-theme";
import { type ColorThemeId, themes } from "./themes";

export const getColorTheme = (colorThemeId: ColorThemeId): ColorTheme => {
	return themes[colorThemeId]?.() || themes.dark_blue();
}

export const getColorThemeVariant = (colorThemeId: ColorThemeId, variant: VariantThemeId): ThemeVariantColors => {
	const theme = getColorTheme(colorThemeId);
	return theme.variants[variant] || theme.variants.white;
}
