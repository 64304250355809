import { Box, Button as ChakraButton, type BoxProps, type ButtonProps as ChakraButtonProps, type LinkProps } from '@chakra-ui/react';
import { useInView } from "framer-motion";
import { forwardRef, useRef, type ReactNode } from 'react';
import Arrow from '../Assets/icons/arrow';
import { type ButtonVariant } from '../Theme/components/Button';
import { animationOptions } from '../Theme/variables/animation';
import { MotionBox } from './motion-box';

type ButtonProps = ChakraButtonProps & LinkProps & {
	icon?: "none" | "left" | "right";
	variant: ButtonVariant;
	children?: ReactNode;
}

export const Button = ({ children, variant, icon = "none", ...rest }: ButtonProps) => (
	<ChakraButton
		variant={variant}
		{...rest}
	>
		<Box w={"full"} display="inline-flex" justifyContent="center" sx={{
			borderBottom: "1px solid transparent",
		}}>
			{icon === "left" && <Arrow fill={["primaryRed", "primaryBlack"].includes(variant) ? "white" : "black"} />}
			{children}
			{icon === "right" && <Arrow fill={["primaryRed", "primaryBlack"].includes(variant) ? "white" : "black"} />}
		</Box>
	</ChakraButton>
)

type TransitionType = {
	once?: boolean,
	wrapperProps?: BoxProps
}

export const PresenceButton = forwardRef(({ children, variant, wrapperProps, once = true, ...props }: ButtonProps & LinkProps & TransitionType) => {
	const inViewRef = useRef<HTMLDivElement>(null)
	const isInView = useInView(inViewRef, { once })

	return (
		<Box ref={inViewRef} overflow={"hidden"} {...wrapperProps}>
			<MotionBox
				initial={{ y: "15%", opacity: 0 }}
				animate={isInView ? { y: 0, opacity: 1 } : {}}
				transition={animationOptions({})}
				{...wrapperProps}
				style={{ willChange: "transform" }}
			>
				<Button
					variant={variant}
					{...props}
				>
					{children}
				</Button>
			</MotionBox>
		</Box>
	)
})

PresenceButton.displayName = "PresenceButton";