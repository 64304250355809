import { type ComponentStyleConfig } from "@chakra-ui/react";
import { pxToRem } from "../utils/mapPxToRem";
import { colors } from "../variables/colors";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const text = (rtl: boolean): ComponentStyleConfig => ({
	baseStyle: {
		fontFamily: "manifa",
		fontWeight: "600",
		lineHeight: "base",
	},
	sizes: {
		// Headers
		headline1: {
			fontSize: ["2.625rem", null, null, "3.75rem"], // [42px, null, null, 60px]
			fontWeight: "600",
			lineHeight: ["1", null, null, "1"], // [42/42, null, null, 60/60]
			letterSpacing: "0em",
		},
		headline2: {
			fontSize: ["2.25rem", null, null, "2.625rem"], // [36px, null, null, 42px]
			fontWeight: "600",
			lineHeight: ["1.028", null, null, "1"], // [37/36, null, null, 42/42]
			letterSpacing: "0em",
		},
		headline3: {
			fontSize: [pxToRem("32px"), null, null, pxToRem("36px")], // [32px, null, null, 36px]
			fontWeight: "600",
			lineHeight: [pxToRem("34px"), null, null, pxToRem("38px")], // [34/32, null, null, 38/36]
			letterSpacing: ["0.02em", null, null, "0.02em"], // [0.64px, null, null, 0.72px]
		},
		headline4: {
			fontSize: ["1.625rem", null, null, "1.875rem"], // [26px, null, null, 30px]
			fontWeight: "600",
			lineHeight: ["1.077", null, null, "1.067"], // [28/26, null, null, 32/30]
			letterSpacing: "0em",
		},
		headline5: {
			fontSize: [pxToRem("22px"), null, null, pxToRem("26px")], // [22px, null, null, 26px]
			fontWeight: "600",
			lineHeight: [rtl ? pxToRem("34px") : pxToRem("25px"), null, null, rtl ? pxToRem("40px") : pxToRem("28px")], // [25/22, null, null, 28/26]
			letterSpacing: "0em",
		},
		headline6: {
			fontSize: [pxToRem("20px"), null, null, pxToRem("22px")], // [20px, null, null, 22px]
			fontWeight: "600",
			lineHeight: [rtl ? pxToRem("33px") : pxToRem("24px"), null, null, rtl ? pxToRem("36px") : pxToRem("25px")], // [24/20, null, null, 25/22]
			letterSpacing: "0em",
		},
		subheader: {
			fontSize: ["1.125rem", null, null, "1.375rem"], // [18px, null, null, 22px]
			fontWeight: "400",
			lineHeight: ["1.222", null, null, "1.136"], // [22/18, null, null, 25/22]
			letterSpacing: "0em",
		},
		bodyBold: {
			fontSize: [pxToRem('16px'), null, null, pxToRem('17px')], // [16px, null, null, 17px]
			fontWeight: "700",
			lineHeight: [pxToRem('24px'), null, null, pxToRem('26px')], // [24/16, null, null, 26/17]
			letterSpacing: "0em",
			fontFamily: "ghawar",
		},
		body: {
			fontSize: [pxToRem('16px'), null, null, pxToRem('17px')], // [16px, null, null, 17px]
			fontWeight: rtl ? "400" : "300",
			lineHeight: [pxToRem('24px'), null, null, pxToRem('26px')], // [24/16, null, null, 26/17]
			letterSpacing: "0em",
			fontFamily: "ghawar",
		},
		smallBodyBold: {
			fontSize: ["0.813rem", null, null, "0.875rem"], // [13px, null, null, 14px]
			fontWeight: "700",
			lineHeight: ["1.538", null, null, "1.571"], // [20/13, null, null, 22/14]
			letterSpacing: "0em",
			fontFamily: "ghawar",
		},
		smallBody: {
			fontSize: ["0.813rem", null, null, "0.875rem"], // [13px, null, null, 14px]
			fontWeight: "300",
			lineHeight: ["1.538", null, null, "1.571"], // [20/13, null, null, 22/14]
			letterSpacing: "0em",
			fontFamily: "ghawar",
		},
		tag: {
			fontSize: pxToRem("10px"), // 10px
			fontWeight: "300",
			lineHeight: pxToRem("16px"), // 16/10
			letterSpacing: "0em",
			fontFamily: "ghawar",
		},
		bread: {
			fontSize: "0.875rem", // 14px
			fontWeight: "200",
			lineHeight: "1.429", // 20/14
			letterSpacing: "0.04em", // 0.56px
		},
		linkBtn: {
			fontSize: ["1.25rem", null, null, "1.375rem"], // [20px, null, null, 22px]
			fontWeight: "600",
			lineHeight: ["1.2", null, null, "1.273"], // [24/20, null, null, 28/22]
			letterSpacing: ["0.01em", null, null, "0.01em"], // [0.2px, null, null, 0.22px]
		},
		moduleLinks: {
			fontSize: "1.125rem", // 18px
			fontWeight: "600",
			lineHeight: "1.222", // 22/18
			letterSpacing: "0em",
		},
		imageCaption: {
			fontSize: pxToRem("14px"),
			fontWeight: "400",
			lineHeight: pxToRem("20px"), // [20/13, null, null, 22/14]
			letterSpacing: pxToRem("0.56px"),
			fontFamily: "ghawar",
		},
		quoteIcon: {
			fontSize: pxToRem("65px"),
			lineHeight: [pxToRem("30px"), null, null, pxToRem("56px")],
			fontWeight: 700,
			letterSpacing: pxToRem("-1px"),
		},
		quoteText: {
			fontSize: pxToRem("30px"),
			lineHeight: pxToRem("32px"),
			fontWeight: 600,
			fontStyle: "normal",
		},
		quoteCite: {
			fontSize: pxToRem("22px"),
			lineHeight: pxToRem("25px"),
			fontWeight: 600,
			fontStyle: "normal",
		},
		quoteCiteTitle: {
			fontSize: pxToRem("14px"),
			lineHeight: pxToRem("22px"),
			fontWeight: 300,
			fontStyle: "normal",
			fontFamily: "ghawar",
		},
		ModuleLinkText: {
			fontSize: [pxToRem("20px"), null, null, pxToRem("22px")], // [20px, null, null, 22px]
			fontWeight: "600",
			lineHeight: pxToRem("28px"),
			letterSpacing: [pxToRem("0.2px"), null, null, pxToRem("0.22px")], // [0.2px, null, null, 0.22px]
		}
	},
	variants: {
		headlineDescBtn: {
			h: "fit-content",
			w: "fit-content",
			display: "inline-flex",
			alignItems: "center",
			gap: 5,
		},
		tag: {
			background: colors.TechBase.dividerGrey,
			padding: `${pxToRem("4px")} ${pxToRem("10px")}`,
		},
		quoteIcon: {
			position: ["static", null, null, "absolute"],
			top: 0,
			left: rtl ? "auto" : 0,
			right: rtl ? 0 : "auto",
			transform: {
				lg: rtl ? "translateX(35px) scaleX(-1)" : "translateX(-35px)"
			},
		}
	},
});
