import { type ReactNode, useEffect, useState } from "react";
import { AspectRatio, Button, Flex } from "@chakra-ui/react";
import zIndices from "../../theme/variables/z-index";
import { createPortal } from 'react-dom';
import { pxToRem } from "../../theme/utils/mapPxToRem";
import BundleIcon from "../ui/bundle-icon";
import { type GlobalDictionary } from "../../../dictionary";
import { useTranslation } from "~/foundation/Dictionary";

type VideoOverlayProps = {
	showOverlayHandle: (showOverlay: boolean) => void;
	children: ReactNode;
}

const VideoOverlay = ({ showOverlayHandle, children }: VideoOverlayProps) => {
	const [t] = useTranslation<GlobalDictionary>();
	const [showOverlay, setShowOverlay] = useState(true);

	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => {
			document.body.style.overflow = "auto";
		}
	}, []);

	useEffect(() => {
		showOverlayHandle(showOverlay);
	}, [showOverlay]);

	return (
		createPortal(<Flex justifyContent="center" alignItems="center" position="fixed" top={0} left={0} zIndex={zIndices.overHeader} w="100vw" h="100vh" background="rgba(0,0,0,0.9)">
			<Button title={t('general.labels.closeLabel')} onClick={() => setShowOverlay(false)} position="absolute" top={pxToRem("30px")} right={pxToRem("30px")} zIndex={1}>
				<BundleIcon name="close" fill="white" size="md" />
			</Button>
			<AspectRatio ratio={16 / 9} w={["80%", null, null, null]} >
				{children}
			</AspectRatio>
		</Flex>, document.body)
	)
}

export default VideoOverlay;
