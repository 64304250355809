import { Box, type BoxProps } from "@chakra-ui/react";
import { type ReactNode } from "react";
import { useColorTheme } from "../theme/ColorThemeContext";
import { ContainerContext } from "./component-container-context";
import { pxToRem } from "../theme/utils/mapPxToRem";
import { Container } from "./container";
import { useSitecoreContext } from "~/foundation/Jss";
import { fadeIn } from "../theme/animations";

type ComponentContainerProps = BoxProps & {
	divider?: "both" | "top" | "bottom" | 'none';
	themed?: boolean;
	innerTopPadding?: string | number | Array<string | number | null>;
	innerBottomPadding?: string | number | Array<string | number | null>;
	isContentSection?: boolean;
	children: ReactNode;
	useInnerContainer?: boolean;
	customBgColor?: string;
	bgImg?: string;
	useOverflowX?: boolean;
};

const subpageContainerStyles = ({
	reversedLinearGradient,
	img,
}: {
	reversedLinearGradient: string;
	img: string;
}) => ({
	background: `${reversedLinearGradient}, url(${img})`,
	backgroundRepeat: "no-repeat",
	backgroundSize: {
		base: "cover",
		lg: "cover",
	},
	backgroundPosition: "right center",
});

const ComponentContainerContent = ({
	divider = "both",
	innerTopPadding = pxToRem("65px"),
	innerBottomPadding = pxToRem("65px"),
	children,
	useInnerContainer = true,
	customBgColor,
	useOverflowX = true,
	bgImg,
}: ComponentContainerProps) => {
	const { moduleBackgroundColor } = useColorTheme();
	const isItPaddingArray = Array.isArray(innerTopPadding);
	const isIbPaddingArray = Array.isArray(innerBottomPadding);
	const isDividerTopOrBoth = divider === "top" || divider === "both";
	const isDividerBottomOrBoth = divider === "bottom" || divider === "both";
	const isTopPaddingZero = typeof innerTopPadding === "number" && innerTopPadding === 0;
	const isBottomPaddingZero = typeof innerBottomPadding === "number" && innerBottomPadding === 0;
	const topPadding = isTopPaddingZero ? "0px" : innerTopPadding;
	const bottomPadding = isBottomPaddingZero ? "0px" : innerBottomPadding;

	const itPadding = isItPaddingArray ?
		(isDividerTopOrBoth ?
			innerTopPadding.map(padding => {
				return `calc(5.49vw + ${isTopPaddingZero ? "0px" : padding})`;
			}) : topPadding)
		: (isDividerTopOrBoth ? `calc(5.49vw + ${topPadding})` : topPadding);

	const ibPadding = isIbPaddingArray ?
		(isDividerBottomOrBoth ?
			innerBottomPadding.map(padding => {
				return `calc(5.49vw + ${isBottomPaddingZero ? "0px" : padding})`;
			}) : bottomPadding)
		: (isDividerBottomOrBoth ? `calc(5.49vw + ${bottomPadding})` : bottomPadding);

	return (
		<Box
			className={`
				${(customBgColor ?? moduleBackgroundColor) === "#fff"
					|| (customBgColor ?? moduleBackgroundColor) === "#FFF"
					|| (customBgColor ?? moduleBackgroundColor) === "rgb(255, 255, 255)"
					|| (customBgColor ?? moduleBackgroundColor) === "rgb(255,255,255)" ? "unthemed" : "themed"} component-container customBgColor-${customBgColor} moduleBackgroundColor-${moduleBackgroundColor}`
			}
			overflowX={useOverflowX ? "hidden" : "visible"}
			// px={["16px", null, null, "32px"]}
			pt={itPadding}
			pb={ibPadding}
			mt={divider === "top" || divider === "both" ? `-5.49vw` : 0}
			mb={divider === "bottom" || divider === "both" ? `-5.49vw` : 0}
			backgroundColor={bgImg ? "transparent" : (customBgColor ?? moduleBackgroundColor)}
		>
			{useInnerContainer ? (
				<Container>
					{children}
				</Container>
			) : (
				<>{children}</>
			)}
		</Box>
	)
}

export const ComponentContainer = ({
	divider = "both",
	themed = false,
	innerTopPadding = pxToRem("65px"),
	innerBottomPadding = pxToRem("65px"),
	children,
	useInnerContainer = true,
	customBgColor,
	bgImg,
	useOverflowX = true,
	...rest
}: ComponentContainerProps) => {
	const { sitecoreContext } = useSitecoreContext();
	const { moduleBackgroundColor } = useColorTheme();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const reversedLinearGradient = `linear-gradient(319.43deg, ${(customBgColor ?? moduleBackgroundColor).replace('rgb', 'rgba').replace(')', ', 0)')} -68.76%, ${(customBgColor ?? moduleBackgroundColor)} 53.27%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`;
	const clipPath = divider === 'none' ? 'unset' : `polygon(0 ${divider === "top" || divider === "both" ? "clamp(0px, 5.49vw, 79px)" : "0px"}, 100% 0, 100% calc(100% - ${divider === "bottom" || divider === "both" ? "clamp(0px, 5.49vw, 79px)" : "0px"}), 0 100%)`;
	const clipPathRTL = divider === 'none' ? 'unset' : `polygon(0 ${divider === "bottom" || divider === "both" ? "calc(100% - clamp(0px, 5.49vw, 79px))" : "100%"}, 100% 100%, 100% ${divider === "top" || divider === "both" ? "clamp(0px, 5.49vw, 79px)" : "0px"}, 0 0)`;

	return (
		<ContainerContext.Provider value={{ divider, themed }}>
			<Box
				width="full"
				position="relative"
				sx={{
					clipPath: !isRtl ? clipPath : clipPathRTL,
				}}
				{...rest}
				backgroundColor={bgImg ? "transparent" : (customBgColor ?? moduleBackgroundColor)}
				animation={`${fadeIn} 1s ease-in-out`}
			>
				{bgImg ?
					<Box
						sx={{
							// Conditonally apply styles based on the page template
							// only apply the styles if the page is not the home page
							...(subpageContainerStyles({ reversedLinearGradient, img: bgImg })),
						}}
					>
						<ComponentContainerContent
							divider={divider}
							themed={themed}
							innerTopPadding={innerTopPadding}
							innerBottomPadding={innerBottomPadding}
							useInnerContainer={useInnerContainer}
							customBgColor={customBgColor}
							bgImg={bgImg}
							useOverflowX={useOverflowX}
						>
							{children}
						</ComponentContainerContent>
					</Box>
					:
					<ComponentContainerContent
						divider={divider}
						themed={themed}
						innerTopPadding={innerTopPadding}
						innerBottomPadding={innerBottomPadding}
						useInnerContainer={useInnerContainer}
						customBgColor={customBgColor}
						bgImg={bgImg}
						useOverflowX={useOverflowX}
					>
						{children}
					</ComponentContainerContent>
				}
			</Box>
		</ContainerContext.Provider>
	);
};
