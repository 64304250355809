import { Box, Flex, Text } from "@chakra-ui/react";
import BundleIcon from "../../_Base/components/ui/bundle-icon";
import { sc } from "../../_Base/Jss";
import { useSitecoreContext, type TextField } from "~/foundation/Jss";
import { useColorTheme } from "../../_Base/theme/ColorThemeContext";
import { pxToRem } from "../../_Base/theme/utils/mapPxToRem";

type InfoRowProps = {
	iconName: "calendar" | "map-pin" | "clock";
	info?: TextField | string;
	isDate?: boolean;
	customColor?: string;
};

type eventInfoProps = {
	date?: TextField | string,
	time?: TextField | string,
	location?: TextField | string,
	customColor?: string;
}

const InfoRow = ({ iconName, info, isDate = false, customColor }: InfoRowProps) => {
	const { moduleBackgroundColor } = useColorTheme();
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;

	return (
		((typeof info === "string" ? info : info?.value) || sitecoreContext.pageEditing) && <Flex flex="1" gap={pxToRem("8px")}>
			<Flex flex="0 0 auto" height={[pxToRem("20px"), null, null, pxToRem("22px")]} alignItems="center">
				<BundleIcon name={iconName} size="sm" fill={customColor ?? moduleBackgroundColor} sx={{
					display: "block",
				}} />
			</Flex>
			<Box flex="1" textAlign={isRtl ? "right" : "left"} color={customColor ?? moduleBackgroundColor}>
				<Text as="p" size="smallBodyBold" textTransform="uppercase">
					{typeof info === "string" ? <Text as="p">{info}</Text> : <>
						{isDate ?
							<sc.Date field={info} />
							:
							<sc.Text field={info} />
						}
					</>
					}
				</Text>
			</Box>
		</Flex>
	)
};

export const EventInfo = ({ date, time, location, customColor }: eventInfoProps) => {
	return (
		<Flex flexDir="column" gap={pxToRem("12px")}>
			<InfoRow iconName="calendar" info={date} isDate={true} customColor={customColor} />
			<InfoRow iconName="clock" info={time} customColor={customColor} />
			<InfoRow iconName="map-pin" info={location} customColor={customColor} />
		</Flex>

	)
}

export const EventBox = ({ date, time, location, customColor, ...rest }: eventInfoProps) => {
	const { moduleBackgroundColor } = useColorTheme();

	return (
		<Flex py={pxToRem("32px")} px={pxToRem("16px")} bg="TechBase.dividerGrey" borderTop={`2px solid ${moduleBackgroundColor}`} {...rest}>
			<EventInfo date={date} time={time} location={location} customColor={customColor} />
		</Flex>
	)
}
